class Elementor_HB_Rooms {
    static instance;

    static getInstance() {
        if (!Elementor_HB_Rooms.instance) {
            Elementor_HB_Rooms.instance = new Elementor_HB_Rooms();
        }
        return Elementor_HB_Rooms.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-hb-rooms.default', ($scope) => {
            let $carousel = $scope.find('.owl-carousel');
            if($carousel.length > 0){
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }

        });
    }
}

Elementor_HB_Rooms.getInstance();